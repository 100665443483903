.carousel-container {
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
}

.carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  height: auto;
}

.carousel-track li::marker {
  display: none;
}

.carousel-slide img {
  width: 100%;
  height: auto;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(27, 17, 17, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  z-index: 5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: transform 0.3s ease, background-color 0.3s ease;
  line-height: 1;
}

.carousel-btn.prev {
  left: 10px;
}

.carousel-btn.next {
  right: 10px;
}

.carousel-btn:hover {
  background-color: rgba(230, 227, 226, 0.164);
  transform: translateY(-50%) scale(1.1);
}